/**
 * A Safe component is an element with sanitized content.
 * It supports these props:
 *   type: element to render, default = div
 *   content: the text to be sanitized
 * Any others are rendered as attributes of the target element.
 */

import React from 'react';
import sanitizeHtml from 'sanitize-html';

export default function Safe(props) {
  const fixedProps = ['type', 'content'];
  const rootProps = Object.keys(props).filter(attrib => !fixedProps.includes(attrib)).reduce((root, attrib) => {
    root[attrib] = props[attrib];
    return root;
    }, {});
  rootProps.dangerouslySetInnerHTML = {__html: sanitizeHtml(props.content || '')};

  const elementType = (props.type ? props.type.toLowerCase() : null);
  if (elementType==='li') return <li {...rootProps}></li>
  else if (elementType==='p') return <p {...rootProps}></p>
  else return <div {...rootProps}></div>
};
