/* A content section that allows more flexible composition of text.
 * Supported props: src
 * Instead of configuring content in "src.text[]" we use "src.data: [{content,items,styling}]"
*/

import React from 'react';
import Safe from './Safe';
import { css } from '../util/pagetools';

export default function ComposedSection(props) {
  const cfg = props.src;
  const pStyle = 'text-black';

  return (
    <div id={cfg.id} className={css('basic-section', cfg.theme)}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2">
            <h1 className={css('section-header', cfg.headerclass)}>
              <span className={css('headline-font', cfg.headerbg)}>{cfg.header || cfg.title}</span>
            </h1>
            {renderParagraphs(cfg.data, pStyle)}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, styling) {
  return parags.reduce((output, paragraph, index) => {
    if (paragraph.items) {
      // Render a list
      output.push(<ul key={index} className="dashed-item">{paragraph.items.map((item, idx) => <li key={`li_${idx}`} className={styling}>{item}</li> )}</ul>);
    } else {
      // Render a paragraph
      const pStyle = (paragraph.styling ? `${styling} ${paragraph.styling}` : styling);
      output.push(<Safe key={index} type="p" className={pStyle} content={paragraph.content} />);
    }
    return output;
    }, []);
}
