/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '../util/pagetools';

export default function Banner(props) {
  return (
    <div id="banner" className={css('row banner', props.banner.theme)}>
      <div className="col-xs-12 col-sm-6">
        <div className="banner-left-content">
          <h1 className="banner-title">{props.title}</h1>
          <div className="banner-text">{props.subtitle}</div>
          <div className="mt-30">
            <Link to="#screener" className={css('get-started-btn', props.banner.btntheme)}>{props.startstudy}</Link>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 banner-img-container">
        <StaticImage src='../images/banner.jpg' alt="main banner" layout="fullWidth" className="hidden-xs" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="visible-xs" />
      </div>
    </div>
  );
};
