/**
 * A panel which appears at a fixed location on scrolling into a given range.
 * Supported props: src btnLabel, visibleLower, visibleUpper
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import Safe from './Safe';
import { css } from '../util/pagetools';

export default function FixedPanel(props) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const scrollFn = scrollMonitor(props.visibleLower, props.visibleUpper);
    function enablePanel() { setHidden(scrollFn()); }
    function watchScroll() { window.addEventListener('scroll', enablePanel); }
    watchScroll();
    // Remove the listener
    return () => { window.removeEventListener('scroll', enablePanel); };
  });

  return (
    <div className={css('begin-fixed', (hidden?'hidden':''))}>
      <div className="container-fluid">
        <div className="row">
          <Safe className="col-xs-8 col-sm-9 begin-fixed-content" content={props.src.cta} />
          <div className="col-xs-4 col-sm-3">
            <Link to="/#screener" className={css('get-started-btn flx-font', props.src.btntheme)}>{props.btnLabel}</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Determine when scrolling enters or exits a given range, specified by one
 * or two elements whose positions represent lower and upper bounds.
 * @param string - id of element of the lower bound
 * @param string - id of element of the upper bound
 * @return boolean
 */
function scrollMonitor(lowerElement, upperElement) {
  function getTop(el) { return el.getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop); }
  const lowerEl = lowerElement ? document.getElementById(lowerElement) : null;
  const upperEl = upperElement ? document.getElementById(upperElement) : null;
  return function() {
    const lowerBoundary = (lowerEl ? lowerEl.scrollHeight+getTop(lowerEl) : 0);
    const upperBoundary = (upperEl ? getTop(upperEl)-200 : 0);
    const hideIt = (upperBoundary>0 ? (window.scrollY<lowerBoundary || window.scrollY>upperBoundary) : (window.scrollY<lowerBoundary))
    // console.log(`Hide panel? ${hideIt?'Y':'N'}, scrollY=${window.scrollY} low=${lowerBoundary}, hi=${upperBoundary}`);
    return hideIt;
  };
}
