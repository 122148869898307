/**
 * A Screener is a container for Slides with logic for conducting a survey.
 * Supported props: config, sitedata, localizerfn
 */
import React from 'react';
import { navigate } from 'gatsby';
import Slide from './Slide';
import PatientForm from './PatientForm';
import LocationsForm from './LocationsForm';
import addScreenerLogic from '../util/screenerNav';
import { adjustSlideHeight } from '../util/pagetools';
import Safe from './Safe';

class Screener extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlideIndex: 0,
      visitedSlideIndex: 0,
      isQualified: false,
      patientData: {},       // candidate info from a form
      slideData: {}          // candidate info from one or more slides
    };
    this.isBrowser = (typeof window !== 'undefined');
    this.cfg = addScreenerLogic(props.config);
    this.advancer = this.advancer.bind(this);
    this.collector = this.collector.bind(this);
    this.setField = this.setField.bind(this);
    this.setLocation = this.setLocation.bind(this);
    this.getPatientLocation = this.getPatientLocation.bind(this);
    this.endpoint = '/.netlify/functions/screenerResults';
    this.sendToFB = this.sendToFB.bind(this);
    this.sendToGA = this.sendToGA.bind(this);
    this.sendToGA_done = this.sendToGA_done.bind(this);
  }

  componentDidMount() {
    const callback = adjustSlideHeight;
    function watchResize() { window.addEventListener('resize', callback); }
    watchResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', adjustSlideHeight);
  }

  /**
   * Changes active slide based on user input.
   * @param int - the index of a slide
   * @param boolean - a boolean user response
   */
  handleScreenerAction(slideIndex, slideReply) {
    const currCfg = this.cfg;
    const canProceed = currCfg.questions[slideIndex].proceed(slideReply);
    this.sendToGA(slideIndex, slideReply);
    if (canProceed) {
      this.nextSlide(this.state.activeSlideIndex+1);
      if (slideIndex>=currCfg.lastQuestionIndex) {
        this.sendToFB(true);
        this.sendToGA_done(true);
        this.setState({isQualified: true});
      }
    } else {
      // Disqualification is indicated by setting activeSlideIndex to index of the fail page
      this.sendToFB(false);
      this.sendToGA_done(false);
      this.nextSlide(currCfg.failPageIndex);
    }
  }

  /**
   * Convenience function to switch to a given slide by its index.
   * @param int - the index of a slide
   * @return none
   */
  nextSlide(slideIndex) {
    this.setState({activeSlideIndex: slideIndex});
    this.setState({visitedSlideIndex: slideIndex});
  }

  /**
   * High order function to provide an event handler
   * @param int - the index of a slide
   * @param boolean - a boolean user response
   * @return function
   */
  advancer(slideIndex, slideReply) {
    const screener = this;
    return function(event) { screener.handleScreenerAction(slideIndex, slideReply); };
  }

  /**
   * Sets the patient data from a form.
   * @param object - field values from a form
   */
  collector(obj) {
    this.setState({patientData: obj});
    if (this.state.isQualified) {
      // Pick a location
      this.nextSlide(this.cfg.locationsIndex);
    } else {
      // Send the patient data and navigate to thank you page
      this.collectAndSend(obj);
      this.navigateToConclusion(false);
    }
  }

  /**
   * Append one or more fields to the patient data.
   * @param object - an object whose fields are to be copied
   */
  setField(obj) { this.setState({slideData: obj}); }

  /**
   * Send patient info and slide info to the backend.
   * @param object - a payload; if none specified patientData is used
   */
  collectAndSend(obj) {
    let payload = obj || this.state.patientData;
    if (Object.keys(this.state.slideData).length>0) {
      payload =  Object.assign(payload, this.state.slideData);
    }
    this.sendPatientData(payload);
  }

  /**
   * Send patient information to a specified endpoint.
   * @param object - the patient information
   */
  sendPatientData(payload) {
    if (!this.endpoint) {
      console.log('Currently not sending to backend. Payload=', payload);
      return;
    }
    fetch(this.endpoint, {
      method: 'POST',
      'Content-Type': 'application/json',
      body: JSON.stringify(payload)
    }).then(resp => {
      return resp.json();
    }).then(data => {
      console.log('Data sent OK', data);
    }).catch(error => {
      console.log('Error with form submission:', error);
      console.log('Payload=', JSON.stringify(payload, null,2));
      navigate(this.props.localizerfn('/generalError/'), {state: {description: error.message}});
    });
  }

  /**
   * Add the selected location to patient information, send to backend, and navigate to conclusion
   * @param object - the selected study location
   */
  setLocation(locationdata) {
    const sitename = (locationdata ? locationdata.name : null);
    this.setState({patientData: Object.assign(this.state.patientData, {sitename})});
    this.collectAndSend();
    this.navigateToConclusion(true, Boolean(sitename));
  }

  /**
   * Navigate to the conclusion page specifying qualification of candidate.
   * @param boolean - is the candidate qualified
   * @param boolean - has a location been selected
   */
  navigateToConclusion(isQualified, locationSelected) {
    const {sitename, siteurl, homelink, footer, sections} = this.props.sitedata;
    const pageProps = {
      header: '',
      text: [''],
      isQualified: isQualified,
      sitedata: {
        sitename,
        siteurl,
        homelink,
        footer,
        sections: sections.map(sec => ({title:sec.title, linkto:sec.linkto}))
      }
    }
    if (isQualified) {
      const success = this.cfg.successResult;
      pageProps.header = (locationSelected ? success.thankyou.locationpicked.header : success.thankyou.nolocation.header);
      pageProps.text = (locationSelected ? success.thankyou.locationpicked.text : success.thankyou.nolocation.text);
      pageProps.theme = (locationSelected ? success.thankyou.locationpicked.theme : success.thankyou.nolocation.theme);
    } else {
      const fail = this.cfg.failResult;
      pageProps.header = fail.thankyou.header;
      pageProps.text = fail.thankyou.text;
      pageProps.theme = fail.thankyou.theme;
    }
    navigate(this.props.localizerfn('/thankyou/'), {state: pageProps});
  }

  /**
   * Retrieve patient location from the state.
   * @return object
   */
  getPatientLocation() {
    return { lat: this.state.patientData.lat, lng: this.state.patientData.lng, country: this.state.patientData.country };
  }

  /**
   * Send data to Facebook.
   * @param boolean - is the candidate qualified
   */
  sendToFB(isQualified) {
    if (this.isBrowser && window.fbq) {
      window.fbq('trackCustom', 'PreScreenerFinish', {status: (isQualified ? 'PD' : 'DQ')});
    }
  }

  /**
   * Send data to Google Analytics for an individual screener question.
   * @param int - the index of the slide (zero-based)
   * @param boolean - whether or not candidate answered "Yes"
   */
  sendToGA(index, isAffirmative) {
    if (this.isBrowser) {
      const stage = (index===0 ? 'screener_start' : 'screener_progress');
      const evLabel = 'Q'+(index+1)+(isAffirmative?'Y':'N');
      if (window.gtag) {
        window.gtag('event', stage, { 'event_category': 'Screener', 'event_label': evLabel });
      }
    }
  }

  /**
   * Send data to Google Analytics for screener conclusion.
   * @param boolean - is the candidate qualified
   */
  sendToGA_done(isQualified) {
    if (this.isBrowser && window.gtag) {
      window.gtag('event', 'screener_finish', { 'event_category': 'Screener', 'event_label': `Screener finished ${(isQualified ? 'Q' : 'DQ')}` });
    }
  }

  render() {
    const currCfg = this.cfg;
    const site = this.props.sitedata;
    const questions = currCfg.questions;
    const locations = currCfg.locations;
    const btnLabels = {
      affirm: currCfg.questionLabel.affirm,
      deny: currCfg.questionLabel.deny
    };
    return (
      <section id="screener" className="screener-section">
        <div className="screener">
          {questions.map((item,idx) => (
            <Slide key={`q-${idx}`}
                   idx={idx}
                   question={item}
                   btnLabels={btnLabels}
                   isActive={this.state.activeSlideIndex===idx}
                   isPast={this.state.visitedSlideIndex>=idx}
                   responder={this.advancer}
            />
          ))}
          <Slide key="success-page"
                 idx={currCfg.successPageIndex}
                 isActive={(this.state.activeSlideIndex===currCfg.successPageIndex)}
                 isPast={this.state.visitedSlideIndex>=currCfg.successPageIndex}
                 theme={currCfg.successResult.theme}>
            <h1 className="slide-h1">{currCfg.successResult.header}</h1>
            <h2 className="slide-h2">{currCfg.successResult.message}</h2>
            {this.state.isQualified &&
              <PatientForm onSubmit={this.collector}
                           theme={currCfg.successResult.theme}
                           sitedata={site}
                           formAction={site.patientFields.cta}
                           isQualified={true} />
            }
          </Slide>
          <Slide key="locations-page"
                 idx={currCfg.locationsIndex}
                 isActive={(this.state.activeSlideIndex===currCfg.locationsIndex)}
                 isPast={this.state.visitedSlideIndex>=currCfg.locationsIndex}
                 theme="bg-locations">
            <div className="location-header">{locations.cta}</div>
            <Safe className="location-desc" content={locations.header} />
            <LocationsForm patientLoc={this.getPatientLocation()} locations={locations} setLocation={this.setLocation} />
          </Slide>
          <Slide key="fail-page"
                 idx={currCfg.failPageIndex}
                 isActive={(this.state.activeSlideIndex===currCfg.failPageIndex)}
                 theme={currCfg.failResult.theme}>
            <h1 className="slide-h1">{currCfg.failResult.header}</h1>
            <p className="slide-h2">{currCfg.failResult.message}</p>
            {!this.state.isQualified &&
              <PatientForm onSubmit={this.collector}
                           theme={currCfg.failResult.theme}
                           sitedata={site}
                           formAction={site.patientFields.ctaDq}
                           isQualified={false} />
            }
          </Slide>
        </div>
      </section>
    );
  }
}
export default Screener;
